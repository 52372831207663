var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"8","md":"12","xl":"6"}},[_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submitAddProduct($event)}}},[_c('b-form-group',{attrs:{"label":"订单编号","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"订单编号","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"请输入订单编号"},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"分担比","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},_vm._l((_vm.formData.share_rate),function(item,index){return _c('div',{key:index,class:['d-flex justify-content-between ', index > 0 ? 'mt-1' : null]},[_c('validation-provider',{staticClass:"flex-grow-1 mr-1",attrs:{"name":"分担比1","rules":"required|number","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"请输入分担比"},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"flex-grow-1 mr-1",attrs:{"name":"分担比2","rules":"required|number","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"请输入分担比"},model:{value:(item.share_rate),callback:function ($$v) {_vm.$set(item, "share_rate", $$v)},expression:"item.share_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-flex"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function () {
                      if (_vm.formData.share_rate.length < 7) {
                        _vm.formData.share_rate.push(_vm.pushData)
                      }
                    }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1),_c('div',{staticStyle:{"width":"5px"}}),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function () {
                      if (_vm.formData.share_rate.length > 1) {
                        _vm.formData.share_rate.splice(index, 1)
                      }
                    }}},[_c('feather-icon',{attrs:{"icon":"DeleteIcon"}})],1)],1)],1)}),0),_c('b-form-group',{attrs:{"label":"订单TP","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"订单TP","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"请输入订单TP值"},model:{value:(_vm.formData.order_tp),callback:function ($$v) {_vm.$set(_vm.formData, "order_tp", $$v)},expression:"formData.order_tp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"会员选择","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"会员","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"tabindex":1,"label":"real_name","reduce":function (item) { return item.id; },"placeholder":"请选择会员","state":errors.length > 0 ? false : null,"options":_vm.vipList},on:{"search":_vm.fetchVipData},model:{value:(_vm.formData.user_id),callback:function ($$v) {_vm.$set(_vm.formData, "user_id", $$v)},expression:"formData.user_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"成交时间","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"成交时间","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig,"placeholder":"请选择成交时间"},model:{value:(_vm.formData.deal_time),callback:function ($$v) {_vm.$set(_vm.formData, "deal_time", $$v)},expression:"formData.deal_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"产品","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"公司品牌","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('vSelect',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"placeholder":"请选择公司","label":"title","reduce":function (item) { return item.id; },"filterable":false,"options":_vm.productList,"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.product_id),callback:function ($$v) {_vm.$set(_vm.formData, "product_id", $$v)},expression:"formData.product_id"}},[_c('li',{staticClass:"pagination_vselest",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('b-button',{attrs:{"disabled":!_vm.hasPrevPage},on:{"click":function($event){$event.preventDefault();_vm.casePagae--}}},[_vm._v("上一页")]),_c('b-button',{attrs:{"disabled":!_vm.hasNextPage},on:{"click":function($event){$event.preventDefault();_vm.casePagae++}}},[_vm._v("下一页")])],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"状态","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"状态","vid":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"tabindex":1,"reduce":function (item) { return item.value; },"placeholder":'请选择状态',"state":errors.length > 0 ? false : null,"options":[
                  { label: '已提交', value: 1 },
                  { label: '已处理', value: 2 },
                  { label: '已生效', value: 3 } ]},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"1","content-cols":"6"}},[_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":_vm.isLoading}},[_vm._v("确认")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }