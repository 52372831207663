<!-- @format -->

<template>
  <b-card>
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="8" md="12" xl="6">
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2" @submit.prevent="submitAddProduct">
            <!-- username -->
            <b-form-group label="订单编号" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="订单编号" rules="required">
                <b-form-input v-model="formData.number" :state="errors.length > 0 ? false : null" placeholder="请输入订单编号" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="分担比" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <div :class="['d-flex justify-content-between ', index > 0 ? 'mt-1' : null]" v-for="(item, index) in formData.share_rate" :key="index">
                <validation-provider #default="{ errors }" name="分担比1" rules="required|number" tag="div" class="flex-grow-1 mr-1">
                  <b-form-input v-model="item.id" :state="errors.length > 0 ? false : null" placeholder="请输入分担比" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider #default="{ errors }" name="分担比2" rules="required|number" tag="div" class="flex-grow-1 mr-1">
                  <b-form-input v-model="item.share_rate" :state="errors.length > 0 ? false : null" placeholder="请输入分担比" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="d-flex">
                  <b-button
                    variant="success"
                    @click="
                      () => {
                        if (formData.share_rate.length < 7) {
                          formData.share_rate.push(pushData)
                        }
                      }
                    "
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <div style="width: 5px" />
                  <b-button
                    variant="danger"
                    @click="
                      () => {
                        if (formData.share_rate.length > 1) {
                          formData.share_rate.splice(index, 1)
                        }
                      }
                    "
                  >
                    <feather-icon icon="DeleteIcon" />
                  </b-button>
                </div>
              </div>
            </b-form-group>
            <b-form-group label="订单TP" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="订单TP" rules="required|number">
                <b-form-input v-model="formData.order_tp" :state="errors.length > 0 ? false : null" placeholder="请输入订单TP值" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="会员选择" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="会员" rules="required">
                <v-select
                  @search="fetchVipData"
                  v-model="formData.user_id"
                  :tabindex="1"
                  label="real_name"
                  :reduce="item => item.id"
                  placeholder="请选择会员"
                  :state="errors.length > 0 ? false : null"
                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                  :options="vipList"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="成交时间" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="成交时间" rules="required">
                <flat-pickr v-model="formData.deal_time" class="form-control" :config="timePickerConfig" placeholder="请选择成交时间" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="产品" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="公司品牌" rules="required">
                <vSelect
                  v-model="formData.product_id"
                  placeholder="请选择公司"
                  label="title"
                  :reduce="item => item.id"
                  :filterable="false"
                  :options="productList"
                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                  :state="errors.length > 0 ? false : null"
                >
                  <li slot="list-footer" class="pagination_vselest">
                    <b-button :disabled="!hasPrevPage" @click.prevent="casePagae--">上一页</b-button>
                    <b-button :disabled="!hasNextPage" @click.prevent="casePagae++">下一页</b-button>
                  </li>
                </vSelect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="状态" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="状态" vid="gender" rules="required">
                <v-select
                  v-model="formData.status"
                  :tabindex="1"
                  :reduce="item => item.value"
                  :placeholder="'请选择状态'"
                  :state="errors.length > 0 ? false : null"
                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                  :options="[
                    { label: '已提交', value: 1 },
                    { label: '已处理', value: 2 },
                    { label: '已生效', value: 3 },
                  ]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--确认-->
            <b-form-group label-cols="1" content-cols="6">
              <b-button type="submit" variant="success" :disabled="isLoading">确认</b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { BRow, BCol, BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue'
import store from '@/store/index'
import { onMounted, reactive, ref, getCurrentInstance, computed } from '@vue/composition-api'
import ListManagerModule from '@/views/GeneralManagement/ProductManager/List/ListManagerModule'
import { required, number } from '@core/utils/validations/validations'
import { heightTransition } from '@core/mixins/ui/transition'
import { useToast } from 'vue-toastification/composition'

import flatPickr from 'vue-flatpickr-component'
import zh from 'flatpickr/dist/l10n'

import axiosIns from '@/libs/axios'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    vSelect,
    ValidationObserver,
    BButton,
    flatPickr,
  },
  mixins: [heightTransition],

  setup() {
    const { proxy } = getCurrentInstance()
    const toast = useToast()
    const timePickerConfig = {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:ss',
      locale: zh.zh, // locale for this instance only
    }

    const pushData = {
      id: undefined,
      share_rate: undefined,
    }

    const formData = reactive({
      product_id: '',
      status: '',
      number: '',
      deal_time: '',
      order_tp: '',
      user_id: '',
      share_rate: [],
    })

    const isLoading = ref(false)
    const submitAddProduct = () => {
      isLoading.value = true
      proxy.$refs.registerForm.validate().then(isOk => {
        if (isOk) {
          console.log(formData)
          store
            .dispatch('user-order/orderCreate', {
              ...formData,
            })
            .then(response => {
              if (response.code === 0) {
                toast.success(response.msg || '添加成功')
                setTimeout(() => {
                  isLoading.value = false
                  proxy.$router.back()
                }, 1000)
              } else {
                isLoading.value = false
                toast.error(response.msg)
              }
            })
            .catch(() => {
              toast.error('添加失败')
              isLoading.value = false
            })
        } else {
          toast.error('请将表单填写完整')
          isLoading.value = false
        }
      })
    }

    const productList = reactive([])
    const casePagae = ref(1)
    const total = ref(0)
    const hasPrevPage = computed(() => casePagae.value > 1)
    const hasNextPage = computed(() => casePagae.value < Math.ceil(total.value / 20))

    // 会员列表
    let vipList = reactive([])

    // 获取产品列表
    const fetchProdList = () => {
      if (!store.hasModule('product-list')) store.registerModule('product-list', ListManagerModule)
      store
        .dispatch('product-list/fetchDataList', {
          pageSize: 20,
          page: casePagae.value,
        })
        .then(response => {
          productList.push(...response.data.list)
          total.value = response.data.total
        })
    }

    const fetchVipData = (search, loading) => {
      if (search.length) {
        loading(true)
        axiosIns.get('/user/list', { params: { name: search } }).then(response => {
          if (response.code === 0) {
            vipList = response.data.list
            loading(false)
          }
        })
      }
    }

    onMounted(() => {
      fetchProdList()
      axiosIns.get('/user/list').then(response => {
        if (response.code === 0) {
          vipList.push(...response.data.list)
        }
      })
      formData.share_rate.push(pushData)
    })

    return {
      formData,
      isLoading,
      submitAddProduct,

      required,
      number,

      productList,
      timePickerConfig,
      casePagae,
      hasPrevPage,
      hasNextPage,
      vipList,
      fetchProdList,
      fetchVipData,
      pushData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.style-chooser .vs__search::placeholder {
  color: #ccc;
}
.style-chooser .vs__dropdown-toggle {
  border: 1px solid red;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
</style>
